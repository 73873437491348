exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-advantage-jsx": () => import("./../../../src/pages/about-us/advantage.jsx" /* webpackChunkName: "component---src-pages-about-us-advantage-jsx" */),
  "component---src-pages-about-us-career-jsx": () => import("./../../../src/pages/about-us/career.jsx" /* webpackChunkName: "component---src-pages-about-us-career-jsx" */),
  "component---src-pages-about-us-company-profile-jsx": () => import("./../../../src/pages/about-us/company-profile.jsx" /* webpackChunkName: "component---src-pages-about-us-company-profile-jsx" */),
  "component---src-pages-ai-assistant-jsx": () => import("./../../../src/pages/ai-assistant.jsx" /* webpackChunkName: "component---src-pages-ai-assistant-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-call-thank-you-jsx": () => import("./../../../src/pages/call-thank-you.jsx" /* webpackChunkName: "component---src-pages-call-thank-you-jsx" */),
  "component---src-pages-career-ty-jsx": () => import("./../../../src/pages/career-ty.jsx" /* webpackChunkName: "component---src-pages-career-ty-jsx" */),
  "component---src-pages-contact-copy-jsx": () => import("./../../../src/pages/contact copy.jsx" /* webpackChunkName: "component---src-pages-contact-copy-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-ty-jsx": () => import("./../../../src/pages/contact-ty.jsx" /* webpackChunkName: "component---src-pages-contact-ty-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-learn-academy-jsx": () => import("./../../../src/pages/learn/academy.jsx" /* webpackChunkName: "component---src-pages-learn-academy-jsx" */),
  "component---src-pages-live-account-ty-jsx": () => import("./../../../src/pages/live-account-ty.jsx" /* webpackChunkName: "component---src-pages-live-account-ty-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-metatrader-jsx": () => import("./../../../src/pages/metatrader.jsx" /* webpackChunkName: "component---src-pages-metatrader-jsx" */),
  "component---src-pages-online-platforms-metatrader-5-jsx": () => import("./../../../src/pages/online-platforms/metatrader-5.jsx" /* webpackChunkName: "component---src-pages-online-platforms-metatrader-5-jsx" */),
  "component---src-pages-online-platforms-saturn-jsx": () => import("./../../../src/pages/online-platforms/saturn.jsx" /* webpackChunkName: "component---src-pages-online-platforms-saturn-jsx" */),
  "component---src-pages-open-an-account-jsx": () => import("./../../../src/pages/open-an-account.jsx" /* webpackChunkName: "component---src-pages-open-an-account-jsx" */),
  "component---src-pages-open-demo-account-jsx": () => import("./../../../src/pages/open-demo-account.jsx" /* webpackChunkName: "component---src-pages-open-demo-account-jsx" */),
  "component---src-pages-open-demo-account-ty-jsx": () => import("./../../../src/pages/open-demo-account-ty.jsx" /* webpackChunkName: "component---src-pages-open-demo-account-ty-jsx" */),
  "component---src-pages-partner-with-us-jsx": () => import("./../../../src/pages/partner-with-us.jsx" /* webpackChunkName: "component---src-pages-partner-with-us-jsx" */),
  "component---src-pages-partnership-jsx": () => import("./../../../src/pages/partnership.jsx" /* webpackChunkName: "component---src-pages-partnership-jsx" */),
  "component---src-pages-seminar-ty-jsx": () => import("./../../../src/pages/seminar-ty.jsx" /* webpackChunkName: "component---src-pages-seminar-ty-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-subscription-thank-you-jsx": () => import("./../../../src/pages/subscription-thank-you.jsx" /* webpackChunkName: "component---src-pages-subscription-thank-you-jsx" */),
  "component---src-pages-tools-financial-calendar-jsx": () => import("./../../../src/pages/tools/financial-calendar.jsx" /* webpackChunkName: "component---src-pages-tools-financial-calendar-jsx" */),
  "component---src-pages-tools-glossary-jsx": () => import("./../../../src/pages/tools/glossary.jsx" /* webpackChunkName: "component---src-pages-tools-glossary-jsx" */),
  "component---src-pages-tools-interest-rates-jsx": () => import("./../../../src/pages/tools/interest-rates.jsx" /* webpackChunkName: "component---src-pages-tools-interest-rates-jsx" */),
  "component---src-pages-tools-market-outlook-foreign-exchange-jsx": () => import("./../../../src/pages/tools/market-outlook/foreign-exchange.jsx" /* webpackChunkName: "component---src-pages-tools-market-outlook-foreign-exchange-jsx" */),
  "component---src-pages-tools-market-outlook-jsx": () => import("./../../../src/pages/tools/market-outlook.jsx" /* webpackChunkName: "component---src-pages-tools-market-outlook-jsx" */),
  "component---src-pages-tools-market-outlook-metal-jsx": () => import("./../../../src/pages/tools/market-outlook/metal.jsx" /* webpackChunkName: "component---src-pages-tools-market-outlook-metal-jsx" */),
  "component---src-pages-tools-market-outlook-stocks-and-indices-jsx": () => import("./../../../src/pages/tools/market-outlook/stocks-and-indices.jsx" /* webpackChunkName: "component---src-pages-tools-market-outlook-stocks-and-indices-jsx" */),
  "component---src-pages-tools-market-outlook-treasuries-and-commodities-jsx": () => import("./../../../src/pages/tools/market-outlook/treasuries-and-commodities.jsx" /* webpackChunkName: "component---src-pages-tools-market-outlook-treasuries-and-commodities-jsx" */),
  "component---src-pages-tools-register-for-free-research-jsx": () => import("./../../../src/pages/tools/register-for-free-research.jsx" /* webpackChunkName: "component---src-pages-tools-register-for-free-research-jsx" */),
  "component---src-pages-tools-technical-strategies-jsx": () => import("./../../../src/pages/tools/technical-strategies.jsx" /* webpackChunkName: "component---src-pages-tools-technical-strategies-jsx" */),
  "component---src-pages-tools-technical-strategies-share-cfds-jsx": () => import("./../../../src/pages/tools/technical-strategies-share-cfds.jsx" /* webpackChunkName: "component---src-pages-tools-technical-strategies-share-cfds-jsx" */),
  "component---src-pages-tools-trading-tools-jsx": () => import("./../../../src/pages/tools/trading-tools.jsx" /* webpackChunkName: "component---src-pages-tools-trading-tools-jsx" */),
  "component---src-templates-blog-detail-jsx": () => import("./../../../src/templates/BlogDetail.jsx" /* webpackChunkName: "component---src-templates-blog-detail-jsx" */),
  "component---src-templates-earn-jsx": () => import("./../../../src/templates/Earn.jsx" /* webpackChunkName: "component---src-templates-earn-jsx" */),
  "component---src-templates-investment-protection-jsx": () => import("./../../../src/templates/InvestmentProtection.jsx" /* webpackChunkName: "component---src-templates-investment-protection-jsx" */),
  "component---src-templates-learn-jsx": () => import("./../../../src/templates/Learn.jsx" /* webpackChunkName: "component---src-templates-learn-jsx" */),
  "slice---src-components-footer-jsx": () => import("./../../../src/components/Footer.jsx" /* webpackChunkName: "slice---src-components-footer-jsx" */),
  "slice---src-components-header-jsx": () => import("./../../../src/components/Header.jsx" /* webpackChunkName: "slice---src-components-header-jsx" */)
}

